import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'
import Image from 'next/image'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'

import { sizes } from '@tofu/apps/shop/styles/variables'

const ImageAndDescription = ({
  hideText,
  title,
  description,
  image,
  alt,
  maxHeight,
  noPadding
}) => (
  <Flex
    alignItems='center'
    flexDirection='column'
    mb={noPadding || hideText ? 0 : [4, 4, 0, 0]}
    px={noPadding ? 0 : [0, 0, 4, 4]}
  >
    {image?.url && (
      <Image
        alt={alt || ''}
        height={maxHeight || image?.dimensions?.height}
        quality={40}
        src={image.url}
        width={image?.dimensions?.width}
      />
    )}
    {title?.[0] && title?.[0].text && (
      <Text
        textStyle='h6'
        fontWeight='ultra'
        as='h3'
        maxWidth='100%'
        mb={1}
        mt={sizes.md}
        textAlign='center'
      >
        {RichText.asText(title)}
      </Text>
    )}
    {description && (
      <Text
        as='div'
        display={hideText ? ['none', 'block', 'block'] : 'block'}
        maxWidth='100%'
        px={2}
        textAlign='center'
      >
        {RichText.render(description)}
      </Text>
    )}
  </Flex>
)

ImageAndDescription.defaultProps = {
  alt: null,
  description: null,
  hideText: false,
  image: null,
  maxHeight: null,
  noPadding: false,
  title: null
}

ImageAndDescription.propTypes = {
  alt: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array]),
  hideText: PropTypes.bool,
  image: PropTypes.shape({
    alt: PropTypes.string,
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    }),
    url: PropTypes.string
  }),
  maxHeight: PropTypes.number,
  noPadding: PropTypes.bool,
  title: PropTypes.arrayOf(PropTypes.object)
}

export default ImageAndDescription
