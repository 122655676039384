import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'
import Link from '@tofu/apps/shop/components/common/link'
import { linkResolver } from '@tofu/apps/shop/services/prismic'
import FourColumContent from '@tofu/apps/shop/components/slices/grid/fourColumnContent'
import { breakpoints } from '@tofu/apps/shop/styles/variables'

const columnDefaultProps = (prefix) => ({
  [`${prefix}_cta_text`]: null,
  [`${prefix}_cta_link`]: null,
  [`${prefix}_description`]: null,
  [`${prefix}_image`]: null,
  [`${prefix}_image_max_height`]: null,
  [`${prefix}_title`]: null
})

const columnPropTypes = (prefix) => ({
  [`${prefix}_cta_text`]: PropTypes.string,
  [`${prefix}_cta_link`]: PropTypes.shape({}),
  [`${prefix}_description`]: PropTypes.arrayOf(PropTypes.object),
  [`${prefix}_image`]: PropTypes.shape({}),
  [`${prefix}_image_max_height`]: PropTypes.number,
  [`${prefix}_title`]: PropTypes.arrayOf(PropTypes.object)
})

const FourColumn = ({
  background_colour,
  cta,
  cta_url,
  description,
  left_cta_text,
  left_cta_link,
  left_description,
  left_image,
  left_image_max_height,
  left_title,
  middle_left_cta_text,
  middle_left_cta_link,
  middle_left_description,
  middle_left_image,
  middle_left_image_max_height,
  middle_left_title,
  middle_right_cta_text,
  middle_right_cta_link,
  middle_right_description,
  middle_right_image,
  middle_right_image_max_height,
  middle_right_title,
  right_cta_text,
  right_cta_link,
  right_description,
  right_image,
  right_image_max_height,
  right_title,
  text_colour,
  theme,
  title
}) => {
  const href = linkResolver(cta_url)

  return (
    <Flex bg={background_colour} data-testid='container' flexDirection='column'>
      <Box maxWidth={`${breakpoints.largeDesktop}px`} mx='auto'>
        <Box color={text_colour} my={4}>
          {title && (
            <Text as='h2' textStyle='h3' textAlign='center'>
              {RichText.asText(title)}
            </Text>
          )}
          {description && (
            <Text
              as='div'
              maxWidth={`${breakpoints.tablet}px`}
              mx='auto'
              my={2}
              textAlign='center'
              textStyle='body-xl'
            >
              {RichText.asText(description)}
            </Text>
          )}
        </Box>
        <Flex
          flexDirection={['column', 'column', 'row', 'row']}
          flexWrap='wrap'
          my={[2, 2, 8, 8]}
          width='full'
        >
          {/* Left Column */}
          <FourColumContent
            ctaLink={left_cta_link}
            ctaText={left_cta_text}
            description={left_description}
            image={left_image}
            maxHeight={left_image_max_height}
            theme={theme}
            title={left_title}
          />
          {/* Middle Left Column */}
          <FourColumContent
            ctaLink={middle_left_cta_link}
            ctaText={middle_left_cta_text}
            description={middle_left_description}
            image={middle_left_image}
            maxHeight={middle_left_image_max_height}
            theme={theme}
            title={middle_left_title}
          />
          {/* Middle Right Column */}
          <FourColumContent
            ctaLink={middle_right_cta_link}
            ctaText={middle_right_cta_text}
            description={middle_right_description}
            image={middle_right_image}
            maxHeight={middle_right_image_max_height}
            theme={theme}
            title={middle_right_title}
          />
          {/* Right Column */}
          <FourColumContent
            ctaLink={right_cta_link}
            ctaText={right_cta_text}
            description={right_description}
            image={right_image}
            maxHeight={right_image_max_height}
            theme={theme}
            title={right_title}
          />
        </Flex>
        {cta && href && (
          <Flex justifyContent='center' my={[4, 4, 0, 0]}>
            <Button
              as={Link}
              href={href}
              label={cta}
              name='cta'
              variant='solid'
              colorScheme='blackAlpha'
              size='lg'
            >
              {cta}
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

FourColumn.defaultProps = {
  background_colour: null,
  cta: null,
  cta_url: null,
  description: null,
  ...columnDefaultProps('left'),
  ...columnDefaultProps('middle_left'),
  ...columnDefaultProps('middle_right'),
  ...columnDefaultProps('right'),
  text_colour: null,
  theme: null,
  title: null
}

FourColumn.propTypes = {
  background_colour: PropTypes.string,
  cta: PropTypes.string,
  cta_url: PropTypes.shape({
    url: PropTypes.string
  }),
  description: PropTypes.arrayOf(PropTypes.object),
  ...columnPropTypes('left'),
  ...columnPropTypes('middle_left'),
  ...columnPropTypes('middle_right'),
  ...columnPropTypes('right'),
  text_colour: PropTypes.string,
  theme: PropTypes.oneOf(['hints_tips']),
  title: PropTypes.arrayOf(PropTypes.object)
}

export default FourColumn
