import PropTypes from 'prop-types'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import Link from '@tofu/apps/shop/components/common/link'
import { Button } from '@tofu/shared/ui/atoms/button'
import { linkResolver } from '@tofu/apps/shop/services/prismic'

import ImageAndDescription from '@tofu/apps/shop/components/slices/imageAndDescription'
import { color } from '@tofu/apps/shop/styles/variables'

const Content = ({
  ctaText,
  ctaLink,
  description,
  image,
  maxHeight,
  title
}) => {
  const href = linkResolver(ctaLink)

  return (
    <>
      {description && title && (
        <ImageAndDescription
          alt={image.alt}
          description={description}
          image={image}
          maxHeight={maxHeight}
          title={title}
        />
      )}
      {ctaText && href && (
        <Flex justifyContent='center' mb={[8, 8, 0, 0]} mt={[0, 0, 8, 8]}>
          <Button
            as={Link}
            href={href}
            label={ctaText}
            name='cta'
            variant='solid'
            colorScheme='blackAlpha'
          >
            {ctaText}
          </Button>
        </Flex>
      )}
    </>
  )
}

Content.defaultProps = {
  ctaLink: null,
  ctaText: null,
  description: null,
  maxHeight: null
}

Content.propTypes = {
  ctaLink: PropTypes.shape({
    url: PropTypes.string
  }),
  ctaText: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  maxHeight: PropTypes.number,
  title: PropTypes.arrayOf(PropTypes.object).isRequired
}

const FourColumContent = ({
  ctaText,
  ctaLink,
  description,
  image,
  maxHeight,
  title,
  theme
}) => {
  if (theme === 'hints_tips') {
    return (
      <Flex
        data-testid='content-box'
        mb={[8, 8, 0]}
        mt={4}
        px={[0, 0, 4, 4]}
        width={['full', 'full', '50%', '25%']}
      >
        <Flex
          bg={color.white}
          flexDirection='column'
          justifyContent='space-between'
          p={4}
          pt={40}
          position='relative'
        >
          <Box
            bg={color.allplantsYellow}
            height={30}
            mx='auto'
            position='absolute'
            top='-15px'
            left={0}
            right={0}
            width={150}
          />
          <Content
            alt={image.alt}
            ctaLink={ctaLink}
            ctaText={ctaText}
            description={description}
            image={image}
            maxHeight={maxHeight}
            title={title}
          />
        </Flex>
      </Flex>
    )
  }
  return (
    <Box
      data-testid='content-box'
      mb={[2, 2, 0, 0]}
      width={['full', 'full', '25%', '25%']}
    >
      <Content
        alt={image.alt}
        ctaText={ctaText}
        ctaLink={ctaLink}
        description={description}
        image={image}
        maxHeight={maxHeight}
        title={title}
      />
    </Box>
  )
}

FourColumContent.defaultProps = {
  ctaLink: null,
  ctaText: null,
  description: null,
  maxHeight: null,
  theme: null
}

FourColumContent.propTypes = {
  ctaLink: PropTypes.shape({
    url: PropTypes.string
  }),
  ctaText: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  maxHeight: PropTypes.number,
  theme: PropTypes.oneOf(['hints_tips']),
  title: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default FourColumContent
